<template>
  <div class="py-5 my-5 overflow-hidden mx-lg-8">
    <playlist-detail :playlist="playlist" v-if="playlist"></playlist-detail>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import PlaylistDetail from "@/components/playlist/PlaylistDetail.vue"
import { SET_TRACK } from "@/utils/mutation-types"
import { loadScript } from "@/utils/misc"

export default {
  name: "PlaylistStream",
  components: { PlaylistDetail },
  computed: {
    ...mapState("playlist", ["playlist"])
  },
  async mounted() {
    loadScript()
    const playlistId = this.$route.params.id
    await this.getPlaylist({ id: playlistId })
    if (this.playlist.tracks.length >= 1) {
      this.setTrack(this.playlist.tracks[0])
    } else {
      this.setTrack(null)
    }
  },
  methods: {
    ...mapActions("playlist", ["getPlaylist"]),
    ...mapMutations("track", { setTrack: SET_TRACK })
  }
}
</script>
