<template>
  <div>
    <video
      id="track-player"
      controls
      fallbackContent="Your browser does not support HTML5 video tags."
      class="cld-video-player cld-video-player-skin-dark cld-fluid"
      autoplay="true"
      @ended="nextTrack"
    ></video>
  </div>

  <div v-if="track">
    <track-delete-modal
      :track_id="track.id"
      :playlist_id="track.playlist_id"
    ></track-delete-modal>
    <track-modal
      :track="track"
      :method="trackMethod"
      ref="trackModal"
    ></track-modal>
    <div class="row justify-content-between">
      <h3 class="col text-start">{{ track.title }}</h3>
      <div class="col dropdown text-end">
        <button
          class="option-btn btn px-1 pb-1"
          type="button"
          id="trackActions"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li v-if="showOption(track)">
            <button class="dropdown-item" @click="openTrackModal">
              Edit Track
            </button>
          </li>
          <li v-if="showOption(track)">
            <button
              class="dropdown-item"
              data-bs-toggle="modal"
              :data-bs-target="'#trackDeleteModal' + track.id"
            >
              Move to trash
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="row text-start">
      <div class="col-4">
        <profile-image :user="playlist.user" class="me-3"></profile-image>
        <router-link
          :to="`/profile/${playlist.user.id}`"
          style="text-decoration: none; color: inherit"
        >
          {{ playlist.user.first_name }} {{ playlist.user.last_name }}
          <i
            class="bi bi-patch-check-fill text-success ms-1"
            v-if="playlist.user.email_verified_at != null"
          ></i>
        </router-link>
      </div>
      <div class="col-8">
        <div class="text-start">Song by {{ track.artist_names }}</div>
        <p class="text-start">
          <small class="text-muted">
            Uploaded on
            {{ moment(track.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
          </small>
        </p>
        <p class="text-start">
          <small class="text-muted">
            <i class="bi bi-eye-fill me-2"></i>{{ track.view_count }} Views
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import moment from "moment"
import { isAuth, loadScript } from "@/utils/misc"
import { Modal } from "bootstrap"
import { getCloudinaryPId } from "@/utils/misc"
import { SET_TRACK } from "@/utils/mutation-types"
import TrackDeleteModal from "@/components/dashboard/playlist/TrackDeleteModal.vue"
import TrackModal from "@/components/dashboard/playlist/TrackModal.vue"
import ProfileImage from "@/components/profile/ProfileImage.vue"
export default {
  name: "TrackPlayer",
  components: { TrackDeleteModal, TrackModal, ProfileImage },
  data() {
    return {
      trackMethod: "edit",
      videoPlayer: null
    }
  },
  mounted() {
    loadScript(this.initPlayer)
  },
  computed: {
    ...mapState("track", ["track"]),
    ...mapState("playlist", ["playlist"]),
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapMutations("track", { setTrack: SET_TRACK }),
    ...mapGetters("playlist", ["getNextTrack"]),
    ...mapActions("track", ["viewTrack"]),
    moment: moment,
    openTrackModal() {
      const modal = new Modal(this.$refs.trackModal.$refs.trackModal)
      modal.show()
    },
    showOption() {
      if (isAuth() && this.playlist.user_id == this.user?.id) {
        return true
      }
      return false
    },
    async nextTrack() {
      const nextTrack = this.getNextTrack()
      if (nextTrack !== null) {
        await this.setTrack(nextTrack)
      }
    },
    getCloudinaryPId: getCloudinaryPId,
    initPlayer() {
      const logUrl =
        "https://res.cloudinary.com/dkj13ry1j/image/upload/v1688931206/Propelond_Logo2_qwybsb.png"
      this.videoPlayer = window.cloudinary.videoPlayer("track-player", {
        cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        secure: true,
        logoImageUrl: logUrl,
        logoOnclickUrl: "https://www.propelond.com/",
        playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 1.25, 1.75, 2],
        colors: { accent: "#FF0DB8" },
        floatingWhenNotVisible: "left",
        seekThumbnails: true
      })
      this.videoPlayer.source(getCloudinaryPId(this.track.song))
      this.videoPlayer.transformation({ quality: "auto" })
      this.viewTrack({ id: this.track.id })
    }
  },
  watch: {
    track(newTrack) {
      this.videoPlayer.source(getCloudinaryPId(newTrack.song))
      this.viewTrack({ id: this.track.id })
    }
  }
}
</script>

<style scoped lang="scss">
.video-player {
  height: 70vh;
}

/* === mobile === */
@media (max-width: 640px) {
  .video-player {
    height: 30vh;
    overflow: hidden;
  }
}
</style>
