<template>
  <div
    class="modal fade"
    id="trackModal"
    tabindex="-1"
    aria-labelledby="trackModalLabel"
    aria-hidden="true"
    ref="trackModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title" style="text-transform: capitalize">
            {{ method }} track
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitTrack">
            <div class="row g-3 mb-3">
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Title"
                  aria-label="Title"
                  required
                  v-model="trackObj.title"
                />
              </div>

              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Artist Name"
                  aria-label="Artist Name"
                  required
                  v-model="trackObj.artist_names"
                />
              </div>

              <div v-if="trackObj.song" class="my-4">
                <i
                  class="bi bi-x-circle-fill fs-4 rounded-circle px-1 close-file-btn"
                  style="float: right"
                  @click="clearVideo"
                ></i>
                <video class="w-100" controls>
                  <source
                    :src="videoUrl ? videoUrl : trackObj.song"
                    type="video/mp4"
                  />

                  Your browser does not support the video tag.
                </video>
              </div>
              <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
              </div>
            </div>
            <div>
              <input
                type="file"
                v-show="false"
                id="video"
                ref="video"
                accept="video/*"
                @change="handleFile"
              />
              <button
                type="button"
                class="btn btn-outline-primary d-flex align-items-center justify-content-center rounded-pill px-5 w-100"
                @click="$refs.video.click()"
              >
                <i class="bi bi-camera-video text-success fs-5 me-1"></i>
                <span class="post-text">Select track video</span>
              </button>
            </div>
            <div class="modal-footer border-0 justify-content-center py-3">
              <button
                type="button"
                class="btn cancel-btn px-5 py-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn submit-btn px-5 py-2 ms-1 ms-lg-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "TrackModal",
  props: {
    track: Object,
    method: {
      type: String,
      default: "create"
    }
  },
  data() {
    return {
      trackObj: null,
      videoUrl: null,
      maxFileSize: 200 * 1024 * 1024, // 200MB
      errorMessage: null
    }
  },
  created() {
    this.trackObj = {
      title: this.track.title,
      artist_names: this.track.artist_names,
      song: this.track.song,
      playlist_id: this.track.playlist_id
    }
  },
  mounted() {
    let modal = this.$refs.trackModal
    modal.addEventListener("hidden.bs.modal", () => {
      this.closeModal()
    })
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapActions("track", ["createTrack", "editTrack"]),
    closeModal() {
      this.videoUrl = null
      this.errorMessage = null
      this.$refs.video.value = ""
      this.$emit("reset-default")
    },
    handleFile(e) {
      let file = e.target.files[0]

      if (file.size > this.maxFileSize) {
        this.errorMessage = "File is too large. Cannot exceed 100MB"
        return
      }

      if (file.type.match("video.*")) {
        this.trackObj.song = file
        this.videoUrl = URL.createObjectURL(file)
      }
      this.errorMessage = null
    },
    submitTrack() {
      if (!this.trackObj.song) {
        this.errorMessage = "You need to select a video"
        return
      }

      let formData = new FormData()
      formData.append("title", this.trackObj.title)
      formData.append("artist_names", this.trackObj.artist_names)
      formData.append("song", this.trackObj.song)
      formData.append("playlist_id", this.trackObj.playlist_id)

      if (this.method == "create") {
        this.createTrack(formData)
        this.$refs.closeButton.click()
      } else {
        formData.append("id", this.track.id)
        this.editTrack(formData)
        this.$refs.closeButton.click()
      }
    },
    clearVideo() {
      this.videoUrl = null
      this.trackObj.song = null
    }
  },
  watch: {
    track(data) {
      this.trackObj = data
    }
  }
}
</script>

<style scoped>
.cancel-btn {
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}
.submit-btn {
  background: linear-gradient(180deg, #ff411f 0%, #ff0bb9 100%);
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}

.close-file-btn {
  top: 30px;
  right: 9px;
  cursor: pointer;
  color: #ffc107;
}

.close-file-btn:hover {
  color: #adb5bd;
}
</style>
