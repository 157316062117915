<template>
  <div class="pb-4">
    <div class="row">
      <div class="col-lg-8 mt-2">
        <track-player></track-player>
      </div>
      <div class="col-lg-4 bg-white border py-4 track-container">
        <div class="row align-items-center justify-content-between">
          <h5 class="text-start col">{{ playlist.name }}</h5>
          <div class="col text-end" v-if="showOption(playlist)">
            <button
              type="button"
              class="btn btn-primary text-white mb-2 me-1"
              @click="openTrackModal"
            >
              Upload
              <i class="bi bi-cloud-arrow-up-fill"></i>
            </button>
          </div>
        </div>
        <track-modal
          :track="editedTrack"
          :method="trackMethod"
          @reset-default="closeTrackModal"
          ref="trackModal"
        ></track-modal>
        <div class="overflow-auto track-list">
          <div v-for="track in playlist.tracks" :key="track.id">
            <div
              class="track mb-2"
              :class="isActiveTrack(track.id) ? 'track-active' : ''"
              @click="openTrack(track)"
            >
              <div class="row gx-2">
                <div class="col-5">
                  <video
                    v-if="track.song"
                    :id="'track-player' + track.id"
                    fallbackContent="Your browser does not support HTML5 video tags."
                    class="cld-video-player cld-video-player-skin-dark cld-fluid border"
                  ></video>
                </div>
                <div class="col-7 text-start">
                  <h5 class="card-title">{{ track.title }}</h5>
                  <div class="text-start">Song by {{ track.artist_names }}</div>
                  <p>
                    <small class="text-muted">
                      Uploaded
                      {{ moment(track.created_at).fromNow() }}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="playlist.tracks.length == 0">No track uploaded yet</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCloudinaryPId } from "@/utils/misc"
import { mapMutations, mapState } from "vuex"
import moment from "moment"
import { Modal } from "bootstrap"
import { isAuth, loadScript } from "@/utils/misc"
import { SET_TRACK } from "@/utils/mutation-types"
import TrackPlayer from "@/components/playlist/TrackPlayer.vue"
import TrackModal from "@/components/dashboard/playlist/TrackModal.vue"

export default {
  name: "PlaylistDetail",
  props: { playlist: Object },
  components: { TrackPlayer, TrackModal },
  data() {
    const editedTrack = {
      title: "",
      artist_names: "",
      song: null,
      playlist_id: this.playlist.id
    }
    return {
      trackMethod: "create",
      playlistMethod: "edit",
      editedTrack,
      defaultTrack: Object.freeze(Object.assign({}, editedTrack))
    }
  },
  mounted() {
    loadScript(this.initPlayers)
  },
  computed: {
    ...mapState("track", ["track"]),
    ...mapState("user", ["user"])
  },
  methods: {
    moment: moment,
    getCloudinaryPId: getCloudinaryPId,
    ...mapMutations("track", { setTrack: SET_TRACK }),
    openTrack(track) {
      this.setTrack(track)
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    },
    isActiveTrack(track_id) {
      return track_id == this.track?.id
    },
    showOption(item) {
      if (isAuth() && item.user.id == this.user?.id) {
        return true
      }
      return false
    },
    openTrackModal() {
      const modal = new Modal(this.$refs.trackModal.$refs.trackModal)
      modal.show()
    },
    closeTrackModal() {
      this.editedTrack = Object.assign({}, this.defaultTrack)
    },
    initPlayers() {
      for (const track of this.playlist.tracks) {
        const videoPlayer = window.cloudinary.videoPlayer(
          `track-player${track.id}`,
          {
            cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
            secure: true,
            logoOnclickUrl: "https://www.propelond.com/"
          }
        )
        videoPlayer.source(getCloudinaryPId(track.song))
        videoPlayer.transformation({ quality: "auto" })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.track:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.track-active,
.track-active:hover {
  background-color: #e0cffc;
}

@media (min-width: 768px) {
  .track-container {
    height: 70vh;
  }
  .track-list {
    height: 60vh;
  }
}
</style>
